import React from 'react'
import { Spin } from 'antd';



const ShowLoading = () => {
    return (
        <div>
            <Spin size="large" />
        </div>
    )
}

export default ShowLoading
